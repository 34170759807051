import React from 'react'
import Slide from './Slide'

const About = () => {
  return (
    <>
    <Slide />

    </>
  )
}

export default About